@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #eee;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
}

.app-button {
  background-color: transparent;
}

.header {
  background-color: #f5ba13;
  height: 70px;
  box-shadow: 0 0 10px 0 #0000004d;
  display: flex;
}

.app-title {
  color: #fff;
  margin: 0 10px;
  padding: 10px;
  font-family: "McLaren", cursive;
}

.footer-container {
  height: 50px;
  position: absolute;
  text-align: center;
  top: 93%;
  left: 50%;
}

.copyright {
  color: #ccc;
}

.note-container {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 10px;
  margin: 16px;
  width: 240px;
  float: left;
}

.note-title {
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.note-content {
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.login-container {
  background: #50a3a2;
  height: 100vh;
  text-align: center;
}

.login-hello-title {
  color: #ddd;
  font-weight: 300;
  font-size: 32px;
  text-align: center;
  margin-bottom: 10px;
}

.form-container {
  width: 400px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 40%;
}

.login-input {
  border: 1px solid #fcfcfc66;
  background-color: #fcfcfc33;
  width: 250px;
  border-radius: 3px;
  padding: 10px 15px;
  margin: 0 auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 300;
}

.login-button {
  border: 1px solid #fcfcfc66;
  background-color: #fff;
  width: 282px;
  border-radius: 3px;
  padding: 10px 15px;
  margin: 0 auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #50a3a2;
  font-weight: 300;
}

.hello-user-body {
  background-color: #50a3a2;
  height: 100vh;
}

.hello-user-container {
  width: 400px;
  font-weight: 200;
  position: absolute;
  top: 40%;
  left: 40%;
}

.login-navigator-container {
  position: absolute;
  top: 20px;
  right: 45px;
  height: 30px;
  width: 50px;
}

.login-link {
  text-decoration: none;
  position: relative;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}

.hello-user-link {
  text-decoration: none;
  position: relative;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}

.todolist-navigator-container {
  position: absolute;
  top: 22px;
  right: 111px;
  height: 30px;
  width: 87px;
}

.todolist-link {
  text-decoration: none;
  position: relative;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}

/* .login-navigator {
  position: relative;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */

.todolist-container {
  background-color: #ead395;
  height: 100vh;
  padding: 10% 40%;
}

.list-container {
  background-color: #eee;
  width: 300px;
  height: auto;
  border-radius: 7px;
  box-shadow: 0 2px 5px #302f2f;
  background-image: radial-gradient(#bfc0c1 5%, transparent 0);
  background-size: 40px 40px;
}

.todolist-title-container {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.todolist-title {
  transform: rotate(2deg);
  padding: 10px 10px;
  border-radius: 20% 5% 20% 5%/5% 20% 25% 20%;
  background-color: #fdcb6e;
  font-size: 25px;
  width: fit-content;
  text-align: center;
  position: relative;
  font-family: "Architects Daughter", cursive;
}

.input-button-container {
  display: flex;
}

.todolist-input {
  border: #ccc;
  border-bottom: 4px dotted #f5ba13;
  margin: 10px;
  margin-right: 15px;
  width: 150px;
  color: #000;
  font-family: "Architects Daughter", cursive;
}

.add-button {
  background-color: transparent;
  width: 50px;
  height: 40px;
  border-radius: 7%;
  margin-top: 13px;
  font-family: "Architects Daughter", cursive;
}

.unorder-list {
  padding: 30px;
}

.list-text {
  font-family: "Architects Daughter", cursive;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  padding: 5px;
}

.close-icon {
  padding-top: 5px;
}

.input-note {
  background: #ffffff;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  text-align: left;
  color: #302f2f;
}

.textarea {
  background: #ffffff;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  color: #302f2f;
}

.create-note-textarea {
  width: 100%;
  border: none;
  padding: 4px;
  outline: none;
  font-size: 1.2em;
  font-family: inherit;
  resize: none;
}

.add-note-container {
  position: relative;
  width: 480px;
  margin: 30px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.add-note-button {
  position: absolute;
  right: 18px;
  bottom: -18px;
  background: #fff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}

.add-note-button:hover {
  background-color: #bfc0c1;
}

.delete-note-button {
  float: right;
  background-color: #fcfcfc;
  color: #f5ba13;
  border: none;
  width: 50px;
  height: 30px;
  cursor: pointer;
  outline: none;
}
